import { FaFacebookF, FaTwitter, FaInstagramSquare } from "react-icons/fa";
import { IoLogoLinkedin, IoLogoYoutube } from "react-icons/io5";

export const socialMediaData = [
  {
    id: 1,
    icon: <FaFacebookF className="text-lg"/>,
  },
  {
    id: 2,
    icon: <FaTwitter className="text-lg"/>,
  },
  {
    id: 3,
    icon: <FaInstagramSquare className="text-lg"/>,
  },
  {
    id: 4,
    icon: <IoLogoLinkedin className="text-lg"/>,
  },
  {
    id: 5,
    icon: <IoLogoYoutube className="text-lg"/>,
  },
];
