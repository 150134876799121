import { Route, Routes } from "react-router-dom";
import { HomePage, LoginPage, RegisterPage, PolicyPage } from "./pages";


function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/private-policy" element={<PolicyPage/>} />
      </Routes>
    </div>
  );
}

export default App;