import React from "react";
import { RegisterForm, RegisterImage } from "../components";

export default function RegisterPage() {
  return (
    <section className="h-screen overflow-hidden">
      <div className="grid md:grid-cols-2 h-full">
        {/* form start */}
        <RegisterForm />
        {/* form end */}

        {/* img start */}
        <RegisterImage />
        {/* img end */}
      </div>
    </section>
  );
}
