import { Accordion, AccordionItem } from "@nextui-org/react";

export default function Faqs() {
  const defaultContent =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

  return (
    <section className="py-20 bg-[#ebebff]">
      {/* heading start */}
      <div className="text-center">
        <h3 className="text-4xl font-bold">Frequently Asked Questions</h3>
      </div>
      {/* heading end */}

      {/* main start */}
      <main className="w-[70%] mx-auto mt-6">
        {/* search input start */}
        <div className="">
          <input
            type="text"
            className="w-full bg-white rounded-full py-3 px-7"
            placeholder="Search questions"
          />
        </div>
        {/* search input end */}

        {/* accordion start */}
        <div className="mt-5">
          <Accordion variant="light">
            <AccordionItem
              key="1"
              aria-label="Accordion 1"
              title="What can Soctario help me do?"
            >
              {defaultContent}
            </AccordionItem>
            <AccordionItem
              key="2"
              aria-label="Accordion 2"
              title="Will my video be transformed to match all social media recommendations?"
            >
              {defaultContent}
            </AccordionItem>
            <AccordionItem
              key="3"
              aria-label="Accordion 3"
              title="How can I upgrade my account?"
            >
              {defaultContent}
            </AccordionItem>
            <AccordionItem
              key="4"
              aria-label="Accordion 4"
              title="Can my single post be posted to all my social media platforms?"
            >
              {defaultContent}
            </AccordionItem>
            <AccordionItem
              key="5"
              aria-label="Accordion 5"
              title="Do you help generate tailored captions with AI?"
            >
              {defaultContent}
            </AccordionItem>
            <AccordionItem
              key="6"
              aria-label="Accordion 6"
              title="How can I connect my social media accounts?"
            >
              {defaultContent}
            </AccordionItem>
          </Accordion>
        </div>
        {/* accordion end */}
      </main>
      {/* main end */}
    </section>
  );
}
