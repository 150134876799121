import React from 'react'
import { LoginForm, LoginImage } from '../components'

export default function LoginPage() {
  return (
    <section className='h-screen overflow-hidden'>
      <div className="grid md:grid-cols-2 h-full">
        {/* form start */}
         <LoginForm />
        {/* form end */}

        {/* img start */}
        <LoginImage />
        {/* img end */}
      </div>
    </section>
  )
}
