import React from "react";
import { Button, Image } from "@nextui-org/react";
import createPostImg1 from "../assets/imgs/illustration1.png";
import createPostImg2 from "../assets/imgs/illustration2.png";
import createPostImg3 from "../assets/imgs/illustration3.png";
import createPostImg4 from "../assets/imgs/illustration4.png";

export default function Features() {
  return (
    <section className="py-20">
      {/* heading start */}
      <div className="">
        <h3 className="md:text-5xl sm:text-4xl text-3xl text-center max-sm:px-3">
          <span className="text-[#ffa500]">Product</span> Features
        </h3>
      </div>
      {/* heading end */}

      {/* first feature start */}
      <main className="sm:px-10 px-5 grid mf:grid-cols-2 mt-[10rem] gap-5">
        {/* illustration start */}
        <div className="">
          <Image src={createPostImg1} />
        </div>
        {/* illustration end */}

        {/* desc start */}
        <div className="sm:px-10 flex flex-col justify-center">
          <h3 className="text-[#ffa500] sm:text-4xl text-3xl font-semibold">
            <span className="text-[#001380]">From</span> One{" "}
            <span className="text-[#001380]">Hub To </span>
            
            Everywhere
          </h3>

          <div className="mt-7">
            <p className="">
            Post Videos, Short Videos, Images, Texts, and More from One Convenient Platform,
            Exploring the Power of Unified Content Sharing.
            </p>
          </div>

          <div className="mt-7">
            <Button
              size="lg"
              className="rounded-md bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold py-5 max-sm:w-full"
            >
              Get Started
            </Button>
          </div>
        </div>
        {/* desc end */}
      </main>
      {/* first feature end */}

      {/* second feature start */}
      <main className="sm:px-10 px-5 grid mf:grid-cols-2 mt-[10rem] gap-5">
        {/* desc start */}
        <div className="sm:px-10 flex flex-col justify-center">
          <h3 className="text-[#001380] sm:text-4xl text-3xl font-semibold">
            <span className="text-[#ffa500]">Tailored </span>
            For
            <span className="text-[#ffa500]"> Success</span>
          </h3>

          <div className="mt-7">
            <p className="">
            Witness Your Video Perfectly Adapted for Every Social Media Platform,
            Ensuring Simultaneous Growth Across All Channels.
            </p>
          </div>

          <div className="mt-7">
            <Button
              size="lg"
              className="rounded-md bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold py-5 max-sm:w-full"
            >
              Get Started
            </Button>
          </div>
        </div>
        {/* desc end */}

        {/* illustration start */}
        <div className="">
          <Image src={createPostImg2} />
        </div>
        {/* illustration end */}
      </main>
      {/* second feature end */}

      {/* third feature start */}
      <main className="sm:px-10 px-5 grid mf:grid-cols-2 mt-[10rem] gap-5">
        {/* illustration start */}
        <div className="">
          <Image src={createPostImg3} />
        </div>
        {/* illustration end */}

        {/* desc start */}
        <div className="sm:px-10 flex flex-col justify-center">
          <h3 className="text-[#ffa500] sm:text-4xl text-3xl font-semibold">
            <span className="text-[#001380]"></span> Fuel{" "}
            <span className="text-[#001380]">
            Your
            </span>{" "}
            Creativity
          </h3>

          <div className="mt-7">
            <p className="">
            Let Our Super AI Spark Ideas and Generate Content for Your Next Post, 
            Allowing You to Grow Smartly and Seamlessly!
            </p>
          </div>

          <div className="mt-7">
            <Button
              size="lg"
              className="rounded-md bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold py-5 max-sm:w-full"
            >
              Get Started
            </Button>
          </div>
        </div>
        {/* desc end */}
      </main>
      {/* third feature end */}

      {/* second feature start */}
      <main className="sm:px-10 px-5 grid mf:grid-cols-2 mt-[10rem] gap-5">
        {/* desc start */}
        <div className="sm:px-10 flex flex-col justify-center">
          <h3 className="text-[#001380] sm:text-4xl text-3xl font-semibold">
            Take <span className="text-[#ffa500]">Control</span> Of Your <span className="text-[#ffa500]">Time</span>
          </h3>

          <div className="mt-7">
            <p className="">
            Plan Your Posts in Advance and Let Us Handle the Rest,
            Ensuring Your Content Reaches Your Audience at the Right Time!
            </p>
          </div>

          <div className="mt-7">
            <Button
              size="lg"
              className="rounded-md bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold py-5 max-sm:w-full"
            >
              Get Started
            </Button>
          </div>
        </div>
        {/* desc end */}

        {/* illustration start */}
        <div className="">
          <Image src={createPostImg4} />
        </div>
        {/* illustration end */}
      </main>
      {/* second feature end */}
    </section>
  );
}
