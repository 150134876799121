import React from "react";
import { Button, Image } from "@nextui-org/react";
import dashboard from "../assets/imgs/Dashboard.png";
import linkedin from "../assets/imgs/linkedin.png";
import instagram from "../assets/imgs/instagram.png";
import tiktok from "../assets/imgs/tiktok.png";
import twitter from "../assets/imgs/twitter.png";
import youtube from "../assets/imgs/youtube.png";

export default function Hero() {
  return (
    <section className="min-h-[90vh] bg-grad relative flex items-center justify-center ">
      <div className="text-center text-white sm:pt-32 pt-24 z-[7]">
        <h3 className="md:text-6xl text-4xl font-bold text-[#ffa500] mb-4">
         Elevate Your Social Media Presence{" "}
        </h3>
        <h5 className="md:text-3xl text-2xl font-bold mb-2">
         Share Across Platforms with Ease, Automatically Tailored for You
        </h5>
        <p className="mb-8 px-15">
          <br />
          
          Join for free in seconds from any device and watch your content <br />{" "}
          soar with tailored transformations
         
        </p>
        {/* email and dashboard start */}
        <div className="min-h-[20rem] py-10 px-10">
          <div className="flex items-center justify-center gap-3 sm:flex-row flex-col">
            <input
              placeholder="Email Address..."
              className="py-3 px-4 rounded-md text-[#151515] focus:outline-none focus:ring-0 sm:w-[20rem] w-full"
            />
            <Button
              size="lg"
              className="rounded-md bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold py-5 max-sm:w-full"
            >
              Join waiting list
            </Button>
          </div>
          <div className="mt-10">
            <Image src={dashboard} />
          </div>
        </div>
        {/* email and dashboard end */}
      </div>

      {/* social icons start */}
      <div className="absolute bottom-0 left-0 w-full z-[5]">
        <div className="flex items-center justify-between">
          <Image src={instagram} className="h-[5rem] sm:h-[10rem] mb-24" />
          <Image src={linkedin} className="h-[5rem] sm:h-[10rem] mb-14" />
          <Image src={twitter} className="h-[5rem] sm:h-[10rem] mb-8" />
          <Image src={tiktok} className="h-[5rem] sm:h-[10rem] mb-4" />
          <Image src={youtube} className="h-[5rem] sm:h-[10rem] mb-1" />
        </div>
      </div>
      {/* social icons end */}
    </section>
  );
}
