import React from 'react'

export default function Statistics() {
  return (
    <section className='bg-[#c6c7f8] py-20'>
       <div className="max-w-6xl mx-auto grid md:grid-cols-3 py-10 bg-[#001380] text-white">
          <div className="text-center md:border-r-2 border-white py-5">
            <h3 className='text-4xl'>99k</h3>
            <p>People have joined</p>
          </div>
          <div className="text-center md:border-r-2 border-white py-5">
            <h3 className='text-4xl'>50k</h3>
            <p>VVIP users have joined</p>
          </div>
          <div className="text-center py-5">
            <h3 className='text-4xl'>100+</h3>
            <p>Big Companies have joined</p>
          </div>
       </div>
    </section>
  )
}