import { Button, Image } from "@nextui-org/react";
import img1 from "../assets/imgs/elipse1.png";
import img2 from "../assets/imgs/elipse2.png";
import img3 from "../assets/imgs/eth.png";
import img4 from "../assets/imgs/luna.png";
import img5 from "../assets/imgs/btc.png";

export default function CTA() {
  return (
    <section className="py-32 bg-[#ebebff]">
      <div className="bg-[#001380] relative text-white w-[80%] py-16 mx-auto rounded-xl">
        <div className="md:px-[6.3rem] px-5 md:py-10 max-md:pt-24 max-md:pb-2">
          <p className="sm:text-3xl text-2xl">
            Join a new generation of <br /> SMART content creators
          </p>
          <div className="mt-3">
            <Button
              size="lg"
              className="rounded-md bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold py-5 max-sm:w-full"
            >
              Get Started
            </Button>
          </div>
        </div>

        <div className="absolute top-1 right-0 max-lg:hidden">
          <Image src={img1} />
        </div>
        <div className="absolute top-0 left-0">
          <Image src={img2} />
        </div>
        <div className="absolute top-20 right-2 max-lg:hidden">
          <Image src={img3} className="h-[7rem]"/>
        </div>
        <div className="absolute -bottom-16 right-[24rem]">
          <Image src={img4} className="h-[7rem]"/>
        </div>
        <div className="absolute -top-10 right-[24rem]">
          <Image src={img5} className="h-[7rem]"/>
        </div>
      </div>
    </section>
  );
}
