import { Button } from "@nextui-org/react";
import { LiaTimesSolid } from "react-icons/lia";

const Policy = ({ onCloseModal }) => {

  return (
    <div className="fixed bg-black/60 h-full w-full top-0 left-0 z-[99] flex items-center justify-center">
      <div className="bg-white w-[45rem] py-5 rounded-lg px-5 max-sm:h-full overflow-y-scroll no-scrollbar">
        {/* heading start */}
        <div className="flex items-center justify-between">
          <h3 className="text-xl text-[#001380] font-bold">Privacy Policy</h3>
          <button className="" onClick={onCloseModal}>
            <LiaTimesSolid className="text-xl" />
          </button>
        </div>
        {/* heading end */}

        {/* privacy policy start */}
        <div className="py-3 my-3 px-4 h-[20rem] scroll mt-5">
          <p className="font-medium text-lg text-[#1B2559] mb-5">
            Artificial Intelligence (AI) offers numerous advantages and has the
            potential to revolutionize various aspects of our lives. Here are
            some key advantages of AI
          </p>
          <ol className="list-decimal text-sm flex flex-col gap-10 ml-4 text-[#1B2559] font-medium text-lg">
            <li className="">
              Automation: AI can automate repetitive and mundane tasks, saving
              time and effort for humans. It can handle large volumes of data,
              perform complex calculations, and execute tasks with precision and
              consistency. This automation leads to increased productivity and
              efficiency in various industries.
            </li>
            <li>
              Decision-making: AI systems can analyze vast amounts of data,
              identify patterns, and make informed decisions based on that
              analysis. This ability is particularly useful in complex scenarios
              where humans may struggle to process large datasets or where quick
              and accurate decisions are crucial.
            </li>
            <li>
              Improved accuracy: AI algorithms can achieve high levels of
              accuracy and precision in tasks such as image recognition, natural
              language processing, and data analysis. They can eliminate human
              errors caused by fatigue, distractions, or bias, leading to more
              reliable and consistent results.
            </li>
            <li>
              Improved accuracy: AI algorithms can achieve high levels of
              accuracy and precision in tasks such as image recognition, natural
              language processing, and data analysis. They can eliminate human
              errors caused by fatigue, distractions, or bias, leading to more
              reliable and consistent results.
            </li>
            <li>
              Improved accuracy: AI algorithms can achieve high levels of
              accuracy and precision in tasks such as image recognition, natural
              language processing, and data analysis. They can eliminate human
              errors caused by fatigue, distractions, or bias, leading to more
              reliable and consistent results.
            </li>
            <li>
              Improved accuracy: AI algorithms can achieve high levels of
              accuracy and precision in tasks such as image recognition, natural
              language processing, and data analysis. They can eliminate human
              errors caused by fatigue, distractions, or bias, leading to more
              reliable and consistent results.
            </li>
            <li>
              Improved accuracy: AI algorithms can achieve high levels of
              accuracy and precision in tasks such as image recognition, natural
              language processing, and data analysis. They can eliminate human
              errors caused by fatigue, distractions, or bias, leading to more
              reliable and consistent results.
            </li>
            <li>
              Improved accuracy: AI algorithms can achieve high levels of
              accuracy and precision in tasks such as image recognition, natural
              language processing, and data analysis. They can eliminate human
              errors caused by fatigue, distractions, or bias, leading to more
              reliable and consistent results.
            </li>
            <li>
              Improved accuracy: AI algorithms can achieve high levels of
              accuracy and precision in tasks such as image recognition, natural
              language processing, and data analysis. They can eliminate human
              errors caused by fatigue, distractions, or bias, leading to more
              reliable and consistent results.
            </li>
          </ol>
        </div>
        {/* privacy policy end */}

        {/* cta start */}
        <div className="px-3">
          <div className="bg-[#001380] text-white rounded-lg flex sm:flex-row flex-col gap-3 justify-between">
            <div className="flex md:flex-row flex-col justify-between sm:border-r-2 px-5 sm:border-[#151515] sm:py-5 py-3">
              <p className="pr-7 pl-2 text-sm">
                You Agree to the terms and condition of postify services
              </p>
              <Button className="bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold px-8">
                I Agree
              </Button>
            </div>
            <div className="sm:px-8 px-5 max-sm:py-3 flex items-center justify-center">
              <Button className="bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold sm:px-8 max-sm:w-full">
                Cancel
              </Button>
            </div>
          </div>
        </div>
        {/* cta end */}
      </div>
    </div>
  );
};

export default Policy;
