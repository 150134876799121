import React from "react";
import Logo from "../assets/Logo.png";
import { Divider, Image, Link, Button } from "@nextui-org/react";
import { socialMediaData } from "../constants";

export default function Footer() {
  return (
    <footer className="md:px-20 sm:px-10 px-3 pt-20 pb-5">
      <div className="grid lg:grid-cols-7 md:grid-cols-3 sm:grid-cols-2 max-sm:gap-10 grid-cols-1">
        {/* logo area start */}
        <div className="sm:p-2 p-1 lg:col-span-2">
          <div className="">
            <Image src={Logo} />
          </div>
          <h3 className="mt-4 text-sm">
            Take your social media content video to the next level
          </h3>
          <div className="mt-5 flex flex-wrap items-center gap-3">
            {
              socialMediaData.map((socials)=>(
                <Button key={socials.id} isIconOnly className="rounded-full text-white bg-[#001380]">
                  {socials.icon}
                </Button>
              ))
            }
          </div>
          <h3 className='mt-4 text-sm'>Take your social media contents to the next level</h3>
        </div>
        {/* logo area start */}

        {/* Company start */}
        <div className="sm:px-5 px-3">
          <h3 className="font-semibold mb-4">Company</h3>
          <div className="flex flex-col gap-4">
            <Link href="#" className="text-gray-600">
              About Us
            </Link>
            <Link href="#" className="text-gray-600">
              Blog
            </Link>
            <Link href="#" className="text-gray-600">
              Careers
            </Link>
          </div>
        </div>
        {/* Company start */}

        {/* Learn start */}
        <div className="sm:px-5 px-3">
          <h3 className="font-semibold mb-4">Learn</h3>
          <div className="flex flex-col gap-4">
            <Link href="#" className="text-gray-600">
              Whats Trending
            </Link>
            <Link href="#" className="text-gray-600">
              Products News
            </Link>
            <Link href="#" className="text-gray-600">
              Events
            </Link>
          </div>
        </div>
        {/* Learn start */}

        {/* Products start */}
        <div className="sm:px-5 px-3">
          <h3 className="font-semibold mb-4">Products</h3>
          <div className="flex flex-col gap-4">
            <Link href="#" className="text-gray-600">
              Stock & Fund
            </Link>
            <Link href="#" className="text-gray-600">
              Cash Card
            </Link>
            <Link href="#" className="text-gray-600">
              Crypto
            </Link>
          </div>
        </div>
        {/* Products start */}

        {/* Support start */}
        <div className="sm:px-5 px-3">
          <h3 className="font-semibold mb-4">Support</h3>
          <div className="flex flex-col gap-4">
            <Link href="#" className="text-gray-600">
              Support Center
            </Link>
            <Link href="#" className="text-gray-600">
              Contact Us
            </Link>
            <Link href="#" className="text-gray-600">
              Privacy Policy
            </Link>
            <Link href="#" className="text-gray-600">
              Terms and Condition
            </Link>
          </div>
        </div>
        {/* Support start */}

        {/* Resources start */}
        <div className="sm:px-5 px-3">
          <h3 className="font-semibold mb-4">Resources</h3>
          <div className="flex flex-col gap-4">
            <Link href="#" className="text-gray-600">
              Prices
            </Link>
            <Link href="#" className="text-gray-600">
              Site Widgets
            </Link>
            <Link href="#" className="text-gray-600">
              Tax
            </Link>
          </div>
        </div>
        {/* Resources start */}
      </div>
      <Divider className="my-5" />
      <p className="text-sm text-center">
        © Soctario&quot; Inc. All rights reserved.
      </p>
    </footer>
  );
}
