import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  User,
} from "@nextui-org/react";

export default function Testimonial() {
  return (
    <section className="py-20 bg-[#001380] text-white">
      {/* heading start */}
      <div className="text-center">
        <h3 className="md:text-5xl sm:text-4xl text-3xl">
          <span className="text-[#ffa500]">Wh</span>at{" "}
          <span className="text-[#ffa500]">people</span> are{" "}
          <span className="text-[#ffa500]">say</span>ing
        </h3>
      </div>
      {/* heading end */}

      {/* main start */}
      <main className="mt-7 w-[80%] mx-auto">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5 items-start">
          <Card>
            <CardHeader className="">
              <User
                name="Jane Doe"
                description="Product Designer"
                avatarProps={{
                  src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                }}
              />
            </CardHeader>
            <CardBody>
              <p className="text-sm">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.”
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className="">
              <User
                name="Jane Doe"
                description="Product Designer"
                avatarProps={{
                  src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                }}
              />
            </CardHeader>
            <CardBody>
              <p className="text-sm">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.”
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className="">
              <User
                name="Jane Doe"
                description="Product Designer"
                avatarProps={{
                  src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                }}
              />
            </CardHeader>
            <CardBody>
              <p className="text-sm">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat."Lorem ipsum dolor sit
                amet, consectetur adipiscing elit,
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className="">
              <User
                name="Jane Doe"
                description="Product Designer"
                avatarProps={{
                  src: "https://i.pravatar.cc/150?u=a04258114e29026702d",
                }}
              />
            </CardHeader>
            <CardBody>
              <p className="text-sm">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.”
              </p>
            </CardBody>
          </Card>
        </div>
      </main>
      {/* main end */}
    </section>
  );
}
