import { Button, Checkbox, Link } from "@nextui-org/react";
import React from "react";

export default function RegisterForm() {
  return (
    <div className="flex items-center overflow-y-scroll pt-40 pb-10 no-scrollbar">
      <div className="w-full">
        <div className="text-center">
          <h3 className="font-bold text-xl">Get Started</h3>
          <p>
            Don&quot;t have an account?{" "}
            <span className="text-[#001380]">Sign up</span>
          </p>
        </div>

        {/* input fields start */}
        <div className="mt-5">
          <div className="sm:w-4/5 w-full px-4 mx-auto mb-5">
            <label className="px-2 mb-1 block">Username</label>
            <input
              type="text"
              placeholder="uistore@gmail.com"
              className="w-full border-[1px] rounded-md border-gray-300 py-2 focus:ring-0 focus:outline-none px-5"
            />
          </div>

          <div className="sm:w-4/5 w-full px-4 mx-auto mb-5">
            <label className="px-2 mb-1 block">Email</label>
            <input
              type="email"
              placeholder="uistore@gmail.com"
              className="w-full border-[1px] rounded-md border-gray-300 py-2 focus:ring-0 focus:outline-none px-5"
            />
          </div>

          <div className="sm:w-4/5 w-full px-4 mx-auto mb-5">
            <label className="px-2 mb-1 block">Country</label>
            <input
              type="text"
              placeholder="Hossan"
              className="w-full border-[1px] rounded-md border-gray-300 py-2 focus:ring-0 focus:outline-none px-5"
            />
          </div>

          <div className="sm:w-4/5 w-full px-4 mx-auto mb-5">
            <label className="px-2 mb-1 block">Password</label>
            <input
              type="password"
              placeholder="uistore@gmail.com"
              className="w-full border-[1px] rounded-md border-gray-300 py-2 focus:ring-0 focus:outline-none px-5"
            />
          </div>

          <div className="sm:w-4/5 w-full px-4 mx-auto mb-5">
            <label className="px-2 mb-1 block">Confirm Password</label>
            <input
              type="password"
              placeholder="uistore@gmail.com"
              className="w-full border-[1px] rounded-md border-gray-300 py-2 focus:ring-0 focus:outline-none px-5"
            />
          </div>

          <div className="sm:w-4/5 w-full px-4 mx-auto flex justify-between flex-wrap my-3">
            <div className="">
                <Checkbox color="secondary"
                classNames={{
                  icon: "text-white",
                }}
                defaultSelected>
                   <span className="sm:text-sm text-[0.8rem]">Remember me</span> 
                </Checkbox>
            </div>
            <div className="">
                <Link href={'#'} className="text-[#001380] font-bold sm:text-sm text-[0.8rem]">Forgot your password?</Link>
            </div>
          </div>

          <div className="sm:w-4/5 w-full px-4 mx-auto">
            <Button className="w-full bg-[#001380] text-white rounded-md py-3">Sign In</Button>
          </div>
        </div>
        {/* input fields end */}
      </div>
    </div>
  );
}
