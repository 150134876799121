import React from "react";
import { Image, Card, CardHeader, CardBody } from "@nextui-org/react";
import Img from "../assets/imgs/film_role.png";

export default function GetStarted() {
  return (
    <section className="py-20">
      {/* heading start */}
      <div className="text-center">
        <h3 className="text-[#2c3131] sm:text-4xl text-3xl font-semibold">
          <span className="text-[#2222f1]"> The</span> {" "}
          <span className="text-[#ffa500]"> Ultimate </span>
          
          <span className="text-[#2222f1]">Start Of Your Social Media</span>{" "}
          <span className="text-[#ffa500]"> Success </span>
        </h3>
        <p className="mt-5">
        Get Started In  a Few Steps to unlock the full potential of 
        Soctario and supercharge your social media presence!
        </p>
      </div>
      {/* heading end */}

      {/* main start */}
      <main className="mt-32 bg-[#ebebff]">
        <div className="py-5 w-4/5 mx-auto grid mf:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 relative -top-20">
          <Card>
            <CardHeader className="flex justify-center py-8">
              <Image src={Img} />
            </CardHeader>
            <CardBody className="max-sm:p-3">
              <h3 className="text-center text-[#2222f1]">Sign Up</h3>
              <p className="mt-3 text-center">
                Create Your Posts, Relax and Watch Your Contents
                Transform to the Best Recommended Specifications, Tailored for Each Social Media.
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className="flex justify-center py-8">
              <Image src={Img} />
            </CardHeader>
            <CardBody className="max-sm:p-3">
              <h3 className="text-center text-[#2222f1]">Transformed Contents Ready</h3>
              <p className="mt-3 text-center">
               Post Now or Later! Choose which platforms to post to.
               One Click Does It All!
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className="flex justify-center py-8">
              <Image src={Img} />
            </CardHeader>
            <CardBody className="max-sm:p-3">
              <h3 className="text-center text-[#2222f1]">Relax and Thrive</h3>
              <p className="mt-3 text-center">
              Congratulations! Sit Back and Watch Your Business Flourish.
              In the Age of Smart Solutions, We've Got You Covered!
              </p>
            </CardBody>
          </Card>
        </div>
      </main>
      {/* main end */}
    </section>
  );
}
