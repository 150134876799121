import {
  Card,
  CardHeader,
  CardBody,
  Divider,
  Button,
} from "@nextui-org/react";
import { GiCheckMark } from "react-icons/gi";

export default function Pricing() {
  return (
    <div className="py-20">
      {/* heading start */}
      <div className="text-center">
        <h3 className="text-[#ffa500] md:text-5xl sm:text-4xl text-3xl">
          <span className="text-[#001380]">O</span>ur Pri
          <span className="text-[#001380]">ci</span>ng
        </h3>
      </div>
      {/* heading end */}

      {/* main start */}
      <main className="w-11/12 mx-auto grid mf:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 mt-6">
        <Card className="p-3 shadow-md">
          <CardHeader className="flex-col items-start">
            <h3 className="font-bold text-3xl mb-5">Basic</h3>
            <h6 className="font-bold text-xl">$10</h6>
          </CardHeader>
          <CardBody>
            {/* description start */}
            <div className="mb-7">
              <p>
              Perfect for beginners and small-scale users, the Basic plan provides 
              essential tools for managing your social media presence effectively
              </p>
            </div>
            {/* description end */}

            <Divider />

            {/* features start */}
            <div className="mt-6">
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#f4ebff] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold text-lg">Amazing feature one</h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#f4ebff] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold text-lg">Wonderful feature two</h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#f4ebff] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold text-lg">
                  Priceless feature three
                </h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#f4ebff] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold text-lg">Splended feature four</h3>
              </div>
            </div>
            {/* features end */}

            {/* try button start */}
            <div className="mt-4">
              <Button
                fullWidth
                className="bg-transparent border-2 border-[#ffa500] text-[#001380] font-bold text-lg py-6"
              >
                Try For Free
              </Button>
            </div>
            {/* try button end */}
          </CardBody>
        </Card>
        <Card className="p-3 shadow-md bg-[#001380] text-white">
          <CardHeader className="flex-col items-start">
            <h3 className="font-bold text-3xl mb-5">Pro</h3>
            <h6 className="font-bold text-xl">$12 <sub className="text-sm text-gray-300">/ month</sub></h6>
          </CardHeader>
          <CardBody>
            {/* description start */}
            <div className="mb-5">
              <p>
              Ideal for growing businesses and content creators, the Pro plan offers advanced features
              to elevate your social media strategy and expand your reach.
              </p>
            </div>
            {/* description end */}

            <Divider />

            {/* features start */}
            <div className="mt-5">
              <h4 className="text-gray-400 mb-4">Everything in the Free plan, plus</h4>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#ffa500] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold">Amazing feature one</h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#ffa500] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold">Wonderful feature two</h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#ffa500] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold">
                  Priceless feature three
                </h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#ffa500] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold">Splended feature four</h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#ffa500] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold">Delightful feature five</h3>
              </div>
            </div>
            {/* features end */}

            {/* try button start */}
            <div className="mt-4">
              <Button
                fullWidth
                className="bg-[#ffa500] border-2 border-[#ffa500] text-[#151515] font-bold py-6"
              >
                Connect accounts
              </Button>
            </div>
            {/* try button end */}
          </CardBody>
        </Card>
        <Card className="p-3 shadow-md">
          <CardHeader className="flex-col items-start">
            <h3 className="font-bold text-3xl mb-5">Enterprise</h3>
            <h6 className="font-bold text-xl">Custom <sub className="text-sm">yearly billing only</sub></h6>
          </CardHeader>
          <CardBody>
            {/* description start */}
            <div className="mb-7">
              <p>
              Enterprise plan delivers comprehensive solutions for managing multiple accounts and 
              maximizing social media impact at scale.
              </p>
            </div>
            {/* description end */}

            <Divider />

            {/* features start */}
            <div className="mt-6">
              <h4 className="mb-2">Everything in the Pro plan, plus</h4>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#f4ebff] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold text-lg">Amazing feature one</h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#f4ebff] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold text-lg">Wonderful feature two</h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#f4ebff] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold text-lg">
                  Priceless feature three
                </h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#f4ebff] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold text-lg">Splended feature four</h3>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <Button
                  isIconOnly
                  radius="full"
                  className="bg-[#f4ebff] text-[#001380]"
                >
                  <GiCheckMark className="text-xl" />
                </Button>
                <h3 className="font-semibold text-lg">Delightful feature five</h3>
              </div>
            </div>
            {/* features end */}

            {/* try button start */}
            <div className="mt-4">
              <Button
                fullWidth
                className="bg-transparent border-2 border-[#ffa500] text-[#001380] font-bold text-lg py-6"
              >
                Contact Sales
              </Button>
            </div>
            {/* try button end */}
          </CardBody>
        </Card>
      </main>
      {/* main end */}
    </div>
  );
}
