import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Image,
  Button,
} from "@nextui-org/react";
import Logo from "../assets/Logo.png";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = ["Product", "Pricing", "Learn", "Support"];

  return (
    <Navbar maxWidth="xl" onMenuOpenChange={setIsMenuOpen} className="bg-white">
      <NavbarBrand className="text-2xl">
        <Image src={Logo} />
      </NavbarBrand>
      <NavbarContent justify="center" className="gap-8 max-lg:hidden">
        <NavbarItem>
          <Link to={"/"} className="font-[500]">
            Product
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link to={"/"} className="font-[500]">
            Pricing
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link to={"/"} className="font-[500]">
            Learn
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link to={"/"} className="font-[500]">
            Support
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end" className="max-sm:hidden">
        <NavbarItem>
          <Link to={"/login"}>
            <Button className="border-gray-200 border-2 bg-transparent text-blue-700 font-semibold">
              Sign in
            </Button>
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link to={"/register"}>
            <Button className="bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold">
              Get started
            </Button>
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarItem className="lg:hidden">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="lg:hidden  py-4 px-3"
        />
      </NavbarItem>

      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenuItem
            key={`${item}-${index}`}
            onClick={() => setIsMenuOpen(false)}
          >
            <Link
              color={
                index === 2
                  ? "primary"
                  : index === menuItems.length - 1
                  ? "danger"
                  : "foreground"
              }
              className="w-full"
              href="#"
              size="lg"
            >
              {item}
            </Link>
          </NavbarMenuItem>
        ))}
        <NavbarMenuItem className="sm:hidden">
          <Link to="/login">
            <Button className="border-gray-200 border-2 bg-transparent text-blue-700 font-semibold">
              Sign in
            </Button>
          </Link>
        </NavbarMenuItem>
        <NavbarMenuItem className="sm:hidden">
          <Button className="bg-gradient-to-l from-[#ffa500] to-[#ffde54] font-semibold w-full">
            Get started
          </Button>
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}
