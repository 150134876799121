import React, { useCallback, useEffect, useState } from "react";

import {
  Features,
  Statistics,
  Header,
  Hero,
  GetStarted,
  Pricing,
  Faqs,
  Testimonial,
  CTA,
  Footer,
  Policy,
} from "../components";

export default function HomePage() {
  const [openModal, setOpenModal] = useState(true);

  const onCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  useEffect(() => {
    if (openModal) return document?.body?.classList.add("overflow-hidden");
    return document?.body?.classList.remove("overflow-hidden");
  }, [openModal]);

  return (
    <div>
      <Header />
      <Hero />
      <Statistics />
      <Features />
      <GetStarted />
      <Pricing />
      <Faqs />
      <Testimonial />
      <CTA />
      <Footer />
      {openModal && <Policy {...{ onCloseModal }} />}
    </div>
  );
}
